import React from 'react';
import dateIcon from '../../images/blog_date.svg';
import readIcon from '../../images/blog_read.svg';
import userIcon from '../../images/blog_user.svg';
import Link from '../../utils/Link';
import Img from '../../utils/OptimizedImage';
import ordinalDate from '../../utils/getOrdinalDate';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const AuthorInfo = ({ read, author, publishDate }) => {
  const date = new Date(publishDate);

  return (
    <div className="author-info">
      <p className="item">
        <Img src={readIcon} alt="Read Time" />
        <span>{read}</span>
      </p>
      {(author?.firstName || author?.lastName) && (
        <p className="item">
          <Img src={userIcon} alt="Author" />
          <Link to={`/blog/author/${author?.slug}`}>
            <span>
              {author?.firstName} {author?.lastName}
            </span>
          </Link>
        </p>
      )}
      <p className="item">
        <Img src={dateIcon} alt="Published Date" />
        <span>
          {`Published on ${monthNames[date.getMonth()]} ${ordinalDate(
            date.getDate()
          )}, ${date.getFullYear()}`}
        </span>
      </p>
    </div>
  );
};

export default AuthorInfo;
